import { apiGet, apiPost } from './service'

export async function listSetting(data) {
	return await apiPost("/api/Setting/list", data);
}

export async function fullListSetting(data) {
	return await apiPost("/api/Setting/fulllist", data);
}

export function getSetting(data) {
	let url = "/api/Setting/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postSetting(data) {
	return apiPost("/api/Setting/edit", data);
}

export function addSetting(data) {
	return apiPost("/api/Setting/add", data);
}

export function deleteSetting(data) {
	return apiGet("/api/Setting/delete/" + data);
}
