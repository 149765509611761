<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<router-link to="/admin/Setting/create" class="btn btn-primary">新增</router-link>
					<button @click="showfilterForm()" class="btn btn-secondary">搜尋</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
									<th width="240px">管理</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.setting_key">
									<td>{{item.setting_key}}</td>
									<td>{{item.setting_name}}</td>
									<td>{{item.setting_value}}</td>
									<td>{{item.setting_describe}}</td>
									<td>{{item.setting_sort}}</td>

									<td>
										<router-link :to="`/admin/Setting/edit/${item.editKey}`"
											class="btn btn-outline-success mx-1">編輯</router-link>
										<button @click="handleDelete(item.delKey)"
											className="btn btn-outline-danger mx-1">
											刪除
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" />

			</div>
		</div>
	</layout-div>
	<div class="modal fade" id="filterModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">搜尋</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="card">
						<form asp-action="Index" id="searchForm">
							<div class="card-body row">
					<div class="col-auto">
						<label class="control-label">鍵值</label>
						<input v-model="filter.setting_key" id="setting_key" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">名稱</label>
						<input v-model="filter.setting_name" id="setting_name" class="form-control" />
					</div>

							</div>
						</form>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="reset()" class="btn btn-primary">清除</button>
					<button type="button" @click="fetchList()" class="btn btn-primary">開始搜尋</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listSetting, deleteSetting } from '@/api/Setting.js'


export default {
	name: 'Settinglist',
	extends: baseList,
	data() {
		return {
			filter: {
					setting_key: "",
					setting_name: "",

			},
			pagination: {
				sortItem: "setting_key",
				sortDir: "ASC",
				page: 1,
				pageSize: 50
			},
			headers: [
				{id: 'setting_key', label: "鍵值", icon: "▼"},
				{id: 'setting_name', label: "名稱", icon: ""},
				{id: 'setting_value', label: "值", icon: ""},
				{id: 'setting_describe', label: "說明", icon: ""},
				{id: 'setting_sort', label: "排序", icon: ""},

			],
			model: [],
			total: 0,
			filterTotal: 0

		};
	},
	methods: {
		reset() {
			this.filter.setting_key = "";
			this.filter.setting_name = "";

		},
		getRequest() {
			var result =
			{
				"filter": this.filter,
				"pagination": this.pagination
			};

			return result;
		},
		fetchList() {

			listSetting(this.getRequest()).then(rep => {

				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc.items;
			});
		},
		deleteRecord(id) {
			return deleteSetting(id);
		},
		showfilterForm() {
			$("#filterModal").modal('show');
		}
	},
};
</script>
